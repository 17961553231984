import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useMemo } from 'react';
import { $path } from 'remix-routes';

import { HomePublicLibraryNav } from '../../app/components/PublicLibrary/HomePublicLibraryNav';
import { PublicLibraryBreadcrumbs } from '../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import { useAppAnalytics } from '../analytics/app/identifiable';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import {
  PageManagerUtils,
  PageSectionUtils,
} from '../components/PageManager/utils';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { GamePackTagDetails } from '../pages/GamePack';
import { apiService } from '../services/api-service';
import { type Tag } from '../types';
import { type GamePack } from '../types/game';
import { usePublicHomeData } from './home.client';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { slug } = action.params;
  if (!slug) {
    throw new Response('Not Found', { status: 404 });
  }

  const tag = (await apiService.tag.getTagBySlug(slug)).data.tag;
  return { tag };
}

export function Component(): JSX.Element {
  const { pageName, page } = usePublicHomeData();
  const { tag } = useLoaderData<typeof clientLoader>();
  const tags = useMemo(() => PageManagerUtils.GetGamePackTags(page), [page]);

  const navigate = useNavigate();
  const analytics = useAppAnalytics();

  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const handleGamePackClick = (
    p: GamePack,
    index: number,
    tag: Tag,
    tagIndex: number,
    section: string
  ) => {
    analytics.trackLibraryGamePackClicked({
      path: window.location.pathname,
      from: 'category page',
      section,

      packId: p.id,
      packName: p.name,
      packIndex: index,
      categoryId: tag.id,
      categoryName: tag.name,
      categoryIndex: tagIndex,
    });
    setActiveGamePack(p);
  };

  return (
    <PublicHomeLayoutV2
      secondaryNav={
        <HomePublicLibraryNav
          pageName={pageName}
          page={page}
          isSectionSelected={(section) =>
            PageSectionUtils.HasTag(section, tag.id)
          }
        />
      }
    >
      <div className='w-full pb-10 bg-modal'>
        <GamePackTagDetails
          tags={tags}
          tag={tag}
          personalized={true}
          onViewTag={(tag) => {
            analytics.trackLibraryCategoryClicked(tag.name);
            navigate($path('/home/tags/:slug', { slug: tag.slug }));
          }}
          onGamePackClick={handleGamePackClick}
          breadcrumb={
            <PublicLibraryBreadcrumbs
              items={[
                { kind: 'link', to: $path('/home'), label: 'Explore' },
                { kind: 'noop', label: tag.name },
              ]}
            />
          }
        />
      </div>
    </PublicHomeLayoutV2>
  );
}
